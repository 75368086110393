import axios from '@/libs/api.request'

//获取导航栏 
export const getNavlist = (params) => {
    return axios.request({
        url: "api.php/Index/nav",
        params,
        method: 'get'
    })
}

//首页数据
export const getHome = (params) => {
    return axios.request({
        url: "api.php/Index/index",
        params,
        method: 'get'
    })
}

//新闻列表
export const newsList = (route, params) => {

    return axios.request({
        url: `api.php/${route.c}/${route.a}`,
        params,
        method: 'get'
    })
}

//新闻详情
export const newsDetail = (route, params) => {
    return axios.request({
        url: `api.php/${route.c}/news_info`,
        params,
        method: 'get'
    })
}