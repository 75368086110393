import Vue from "vue";
import VueRouter from "vue-router";
//import Router from "vue-router";


Vue.use(VueRouter);
const routes = [{
        path: "*",
        name: "indexView",
        redirect: '/'
    },
    { //首页
        path: "/",
        name: "indexView",
        component: () => import("../view/home/indexView.vue")
    },
    { //新闻列表
        path: "/news",
        name: "newsView",
        component: () => import("../view/newslist/indexView.vue")
    },
    { //视听政协
        path: "/video",
        name: "videoView",
        component: () => import("../view/video/indexView.vue")
    },
    { //视频详情
        path: "/videodetail",
        name: "videoDetail",
        component: () => import("../view/videodetail/indexView.vue")
    },
    { //新闻详情
        path: "/newsdetail",
        name: "newsDetail",
        component: () => import("../view/newsdetail/indexView.vue")
    },
    { //新闻详情
        path: "/active",
        name: "active",
        component: () => import("../view/active/indexView.vue")
    },

];
const scrollBehavior = function (to, from, savedPosition) {
    // savedPosition 会在你使用浏览器前进或后退按钮时候生效
    // 这个跟你使用 router.go() 或 router.back() 效果一致
    // 这也是为什么我在 tab 栏结构中放入了一个 点击回退 的按钮
    if (savedPosition) {
        return savedPosition
    } else {
        // 如果不是通过上述行为切换组件，就会让页面回到顶部
        return {
            x: 0,
            y: 0
        }
    }
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
});

export default router;